export default {
  colors: {
    // Background color for gradient
    BG200_G_START: 'rgba(244,240,237,0)',

    // Backgrounds
    BG100: '#FFFFFF', // White
    BG200: '#FEFEFE', // Header
    BG250: '#fbfbfc',
    BG300: '#F8F8FA', // Background
    BG350: '#F0F1F0', // Disabled fields
    BG400: '#F1F0F5', // Dark section
    BG900: '#00000080', // Dark section
    BG_PURPLE: '#F8F6F9',

    // Texts
    INK400: '#C4BFCF', // Sections
    INK500: '#A8A1B0', // Body light and labels
    INK900: '#635F66', // Headers and body

    // Horizontal line
    LINE: '#7263B915',
    INPUT_BORDER: '#7263B930',

    // Buttons and borders
    PRI200: '#F1D6DA', // Basket number
    PRI400: '#E0ADB4', // Bordered buttons
    PRI800: '#A74C75', // Text buttons
    PRI900: '#8C3C60', // Rect buttons
    PRI_DISABLED: '#E2D1D9', // Disabled buttons
    BACK_BUTTON: '#ECE7F0',

    // Basic chips
    BLUE100: '#D0D7D6',

    // Switch and grouped button
    BLUE900: '#5F4992',

    // Card statuses
    LILAC700: '#9585B5',
    LILAC400: '#D8D0E8', // Statuses
    RED400: '#de838c',
    GREEN400: '#95c1b6',
    GREEN700: '#70BB93',
    GREEN900: '#31b131',
    BLUE400: '#93bedb',

    FIELD_SHADOW: '#00000010',
    PAPER_SHADOW: '#00000020',
    CARD_SHADOW: '#653ED320',
    HEADER_SHADOW: '#653ED305',

    // Destructive buttons
    RED700: '#C56E6E', // Destructive buttons
    RED900: '#C14645',

    // Stars review
    YELLOW400: '#efbc8e',

    // Referal and background images
    PURPLE700: '#D9CFE8',
    PURPLE500: '#946c83',
    PURPLE900: '#5d405b',

    // Female chip
    PINK500: '#DBAEDA',

    // Avatar empty
    AVATAR300: '#F2D6DA',

    // Old daily check
    ORANGE200: '#FFC493',
  },
  fonts: {
    muli: 'Muli, sans-serif',
    quiche: 'Quiche-Sans',
  },
}
